import React from 'react';
import { Content, HeaderManager, MyTools, Skeleton } from '@campus/components';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '@campus/commons';

import PedagogicalPlanningIcon from '@campus/components/assets/icons/pedagogical-planning.svg';
import VestibularIcon from '@campus/components/assets/icons/vestibular-icon.svg';
import ReportIcon from '@campus/components/assets/icons/report-icon.svg';
import skillManagementIcon from '@campus/components/assets/icons/skill-management.svg';

import { listManagerGradeClasses } from 'services/managerClass';
import { IManagerGradeBySegment } from 'models/ManagerGradeClassBySegment';

import SearchPerson from './components/SearchPerson';
import ClassCard from './components/ClassCard';
import { ContentStyled, Line, Title, Root } from './styles';

const Home: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const { breakpoint } = useWindowDimensions();

  const tools = [
    {
      name: 'Planejamento Pedagógico',
      click: () => {
        window.open('/planning');
      },
      icon: {
        src: PedagogicalPlanningIcon,
        alt: 'Planejamento Pedagógico',
        bgColor: '#F0F0F0'
      }
    },
    {
      name: 'Campus Vestibular',
      click: () => {
        navigate('/vestibular');
      },
      icon: {
        src: VestibularIcon,
        alt: 'Campus Vestibular',
        bgColor: '#F0F0F0'
      }
    },
    {
      name: 'Relatório do Planejamento',
      click: () => {
        navigate('/planning/launch-report');
      },
      icon: {
        src: ReportIcon,
        alt: 'Relatório do Planejamento Pedagógico',
        bgColor: '#F0F0F0'
      }
    },
    {
      name: 'Gestão das habilidades',
      click: () => {
        navigate('/planning/skill-management');
      },
      icon: {
        src: skillManagementIcon,
        alt: 'Gestão de Habilidades',
        bgColor: '#F0F0F0'
      }
    }
  ];

  const [gradesBySegment, setGradesBySegment] = React.useState<
    IManagerGradeBySegment[]
  >([]);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const result = await listManagerGradeClasses();

      if (result.data) {
        setGradesBySegment(result.data);
      }

      setLoading(false);
    };

    getData();
  }, []);

  return (
    <Root>
      <HeaderManager
        title="Acompanhamento Geral"
        subTitle="Acompanhe os indicadores gerais sobre as séries"
      />
      {loading ? (
        <Content>
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div className="mb-5" key={index}>
                <Skeleton height="40px" width="100%" className="mb-3" />
                <Skeleton height="202px" width="100%" />
              </div>
            ))}
        </Content>
      ) : (
        <>
          <ContentStyled>
            <MyTools tools={tools} breakpoint={breakpoint} />
            <Line />
          </ContentStyled>
          <SearchPerson />
          {gradesBySegment.map((item) => (
            <Content key={item.segment}>
              <Title>{item.segment}</Title>
              {item.managerGrades.map((grade) => (
                <ClassCard grade={grade} />
              ))}
            </Content>
          ))}
        </>
      )}
    </Root>
  );
};

export default Home;
