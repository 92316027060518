import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { managerTheme } from '@campus/components';
import { ContextType } from '@campus/auth';
import { AppContainer, ContextManagerProvider } from '@campus/commons';
import {
  Feedback,
  ClassroomManager,
  StudentMonitoring,
  Variant
} from '@campus/classroom';

import Menu from 'containers/Menu';

import { createPedagogicalPlanningRoutes } from '@campus/pedagogical-planning';
import { createVestibularRoutes } from '@campus/vestibular';

import { Home } from 'pages';
import { userHasVestibularManagementPermission } from 'services/userHasVestibularManagementPermission';

const App: React.FC = () => {
  const [hasManagementPermission, setHasManagementPermission] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const hasManagementPermissionResponse =
          await userHasVestibularManagementPermission();
        setHasManagementPermission(hasManagementPermissionResponse.data);
      } catch {
        setHasManagementPermission(false);
      }
    };

    fetchPermission();
  }, []);

  return (
    <AppContainer
      theme={managerTheme}
      contextType={ContextType.MANAGER}
      components={{ Menu: Menu, MainProvider: ContextManagerProvider }}
    >
      <Route index element={<Home />} />
      <Route
        path="class/:classId"
        element={<ClassroomManager variant={Variant.MANAGER} />}
      />
      <Route
        path="student/:studentId"
        element={<StudentMonitoring variant={Variant.MANAGER} />}
      >
        <Route
          index
          element={
            <StudentMonitoring variant={Variant.MANAGER} tab="indicator" />
          }
        />
        <Route
          path="skills"
          element={<StudentMonitoring variant={Variant.MANAGER} tab="skill" />}
        />
        <Route path="feedback" element={<Feedback />} />
      </Route>

      {createPedagogicalPlanningRoutes({
        launchReport: { enable: true }
      })}

      {createVestibularRoutes({
        pages: {
          allowAccessToAnUnpublishedWork: true,
          literaryWorks: {
            isReadOnly: false
          },
          literaryWork: {
            isReadOnlyOnGeneral: false,
            questionsCanBe: 'managed'
          }
        },
        hasManagementPermission: hasManagementPermission
      })}
    </AppContainer>
  );
};

export default App;
